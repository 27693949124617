<template>
  <component-page :code-view="codeView">
    <template #description>
      Таблица
    </template>
    <template #component>
      <div class="trainspotting--bg pa-5" style="width: 100%;">
        <r-table
          v-on="getListener"
          v-model="model"
          :headers="headers"
          :items="items"
          :sort.sync="sort"
          v-bind="props">
          <template #noData v-if="isNotItemsSlot">
            <div class="leon--bg fightclub--text d-flex justify-content-center">Slot #noData</div>
          </template>
          <template #headers v-if="isSlotHeaders">
            <div class="leon--bg fightclub--text d-flex justify-content-center">Slot #headers</div>
          </template>
          <template v-slot:number="{ item }">
            {{ item.children ? `${item.children.length} авто` : item.number }}
          </template>
          <template v-slot:boardNumber="{ item }">
            {{ item.children ? `${item.children.length} авто` : item.boardNumber }}
          </template>
          <template v-slot:mileage="{ item }">
            {{ item.children ? `${item.children.length} авто` : item.mileage }}
          </template>
          <template v-slot:contentTable="{ item }">
            <div  class="leon--bg px-4 py-2 fightclub--text caprino d-flex justify-content-center">
              Слот строки - {{ item.model }}
            </div>
          </template>
        </r-table>
      </div>
    </template>

    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="Preview Mode"
          v-model="props.isPreview"
        />
        <r-switcher
          title="Clear items"
          v-model="isNotItems"
        />
        <r-switcher
          title="Clear items slot"
          v-model="isNotItemsSlot"
        />
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
        />
        <r-switcher
          title="isMultiSort"
          v-model="props.isMultiSort"
        />
      </r-row>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="Selected Mode"
          sub-title="props [idValue]"
          v-model="isSelected"
        />
        <r-switcher
          title="Select children"
          v-model="props.isSelectChildren"
        />
        <r-switcher
          title="isSort Mode"
          sub-title="headers property [sort]"
          v-model="isSort"
        />
        <r-switcher
          title="Grouping columns"
          sub-title="headers property [children]"
          v-model="groupHeader"
        />
        <r-switcher
          title="Collapse Mode"
          sub-title="items property [children]"
          v-model="isCollapse"
        />
        <r-switcher
          title="Collapse Mode Slot"
          sub-title="items property slotCollapse"
          v-model="isCollapseSlot"
        />
        <r-switcher
          title="ClickRow Event"
          sub-title="Emit ClickRow full tr block and return item"
          v-model="isClickRow"
        />
        <r-switcher
          title="isSlotHeaders"
          sub-title="Headers slot"
          v-model="isSlotHeaders"
        />
        <r-switcher
          title="isFixedHeader"
          sub-title="Header fixed"
          v-model="props.isFixedHeader"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            :items="sizeData"
            v-model="props.size"
          />
        </r-col>
        <r-col :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="ColorHeader"
            :items="colorData"
            v-model="props.colorHeader"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
        <template v-slot:contentTable="{ item }">
          <div class="burrata mb-4">
            HeadersTable
          </div>
          <r-table
            class="mb-8"
            :headers="headersPropsChildren"
            :items="item.headersTable"
          >
            <template #nameProp="{ item }">
              <div  v-html="item.nameProp"/>
            </template>
            <template #typeData="{ item }">
              <div  v-html="item.typeData"/>
            </template>
            <template #defaultValue="{ item }">
              <div  v-html="item.defaultValue"/>
            </template>
          </r-table>
          <div class="burrata my-4">
            HeadersGroup
          </div>
          <r-table
            class="mb-8"
            :headers="headersPropsChildren"
            :items="item.headersGroup"
          >
            <template #nameProp="{ item }">
              <div  v-html="item.nameProp"/>
            </template>
            <template #typeData="{ item }">
              <div  v-html="item.typeData"/>
            </template>
            <template #defaultValue="{ item }">
              <div  v-html="item.defaultValue"/>
            </template>
          </r-table>
        </template>
      </r-table>
      <div class="taleggio mb-8">SlotsScoped</div>
      <r-table
        class="mb-8"
        :headers="headersSlotsScoped"
        :items="itemsSlotsScoped"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
        <template #props="{ item }">
          <div v-html="item.props" />
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
    <template #examples>
      <table-fixed-header />
      <table-set-width-column />
      <table-popover-action-column />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import TablePopoverActionColumn from '@/components/examples/Table/TablePopoverActionColumn.vue';
import TableFixedHeader from '@/components/examples/Table/TableFixedHeader.vue';
import TableSetWidthColumn from '@/components/examples/Table/TableSetWidthColumn.vue';

@Component({
  components: {
    TableSetWidthColumn, TableFixedHeader, TablePopoverActionColumn, ComponentPage,
  },
})
export default class Table extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersPropsChildren = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlotsScoped = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'props',
      name: 'props',
      description: 'Входные параметры слота',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">&lt;idValue&gt;[]</span>',
      defaultValue: '<span class="rocky--text">[]</span>',
      description: 'Модель компонента, при использование выбора строк',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"paphia"</span>',
      description: 'Размер таблицы (влияете на отступы в ячейках)',
    },
    {
      nameProp: '<span class="rocky--text">sort | sort.sync</span>',
      typeData: '<span class="matrix--text">array</span>',
      defaultValue: '<span class="rocky--text">SortColumn[]</span>',
      description: 'Sync параметр для сортировки полей',
      children: [
        {
          nameProp: '<span class="rocky--text">field</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Наименование свойства в объекте элемента или название слота',
        },
        {
          nameProp: '<span class="rocky--text">order</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Тип сортировки ASC | DESC',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">headers</span>',
      typeData: '<span class="matrix--text">(HeadersTable | HeadersGroup)[]</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Список колонок для вывода в таблице',
      slotCollapse: true,
      headersTable: [
        {
          nameProp: '<span class="rocky--text">name</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Название колонки',
        },
        {
          nameProp: '<span class="rocky--text">description</span>',
          typeData: '<span class="matrix--text">string</span>',
          description: 'Описание колонки, выводится в Tooltip',
        },
        {
          nameProp: '<span class="rocky--text">field</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Наименование свойства в объекте элемента или название слота',
        },
        {
          nameProp: '<span class="rocky--text">alignPosition</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">"center" | "end"</span>',
          description: 'Выравнивание заголовка колонки',
        },
        {
          nameProp: '<span class="rocky--text">slot</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Использовать slotScoped для вывода данных, возвращает полный элемент строки "{ item }"',
        },
        {
          nameProp: '<span class="rocky--text">sort</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Включить поле для сортировки',
        },
        {
          nameProp: '<span class="rocky--text">ignoreClickRow</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Игнорировать колонку по клику на всю строку',
        },
        {
          nameProp: '<span class="rocky--text">style</span>',
          typeData: '<span class="matrix--text">Pick&lt;CSSStyleDeclaration, \'textAlign\' | \'width\'&gt;</span>',
          description: 'Стили для ячеек в строкак',
        },
      ],
      headersGroup: [
        {
          nameProp: '<span class="rocky--text">headerName</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Название колонки',
        },
        {
          nameProp: '<span class="rocky--text">children</span>',
          typeData: '<span class="matrix--text">array</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Список группированных колонок headersTable[]',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="matrix--text">(ItemTable & {[key: string]: any})[]</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Набор данных для вывода в таблицу',
      children: [
        {
          nameProp: '<span class="rocky--text">children</span>',
          typeData: '<span class="matrix--text">array</span>',
          description: 'Список вложенных строк у строки',
        },
        {
          nameProp: '<span class="rocky--text">slotCollapse</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Вложенный слот у строки (приоритетней children)',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">noData</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"Таких данных нет, попробуйте изменить параметры"</span>',
      description: 'Текст при пустом списке "items" (слот noData приоретней данного входного параметра)',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Названия свойства из объекта списка для определения по уникальному идентификатору, активирует режим выбора',
    },
    {
      nameProp: '<span class="rocky--text">isSelectChildren</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Включает выбор потомков для модели',
    },
    {
      nameProp: '<span class="rocky--text">isMultiSort</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="matrix--text">false</span>',
      description: 'Сортировка по нескольким полям одновременно',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="matrix--text">false</span>',
      description: 'Состояние ожидания',
    },
    {
      nameProp: '<span class="rocky--text">isFixedHeader</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="matrix--text">false</span>',
      description: 'Фиксировать шапку при прокрутки контента (фиксация зафисит от контента со скролом)',
    },
    {
      nameProp: '<span class="rocky--text">topOffsetFixed</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="matrix--text">0</span>',
      description: 'Отступ сверху от контета скролла при фиксированнй шапки',
    },
    {
      nameProp: '<span class="rocky--text">colorHeader</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">null</span>',
      description: 'Цвет шапки для покраски при фиксированной шапки',
    },
    {
      nameProp: '<span class="rocky--text">isPreview</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="matrix--text">false</span>',
      description: 'Превью состояние таблицы, для показа одной строки с блоками',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента, при выборке строк',
    },
    {
      nameEvent: '<span class="rocky--text">update:sort</span>',
      description: 'Возвращает текущую сортировку после события',
    },
    {
      nameEvent: '<span class="rocky--text">clickRow</span>',
      description: 'Отрабатывает клик по всей строке и возвращает элемент строки',
    },
  ]

  itemsSlotsScoped = [
    {
      nameSlot: '<span class="rocky--text">[field]</span>',
      props: '<span class="rocky--text">{ item: (ItemTable & {[key: string]: any}) }</span>',
      description: 'Колонка при использование header.slot',
    },
    {
      nameSlot: '<span class="rocky--text">contentTable</span>',
      props: '<span class="rocky--text">{ headers: HeadersTable[], item: (ItemTable & {[key: string]: any}) }</span>',
      description: 'Возвращает в слот список headers и элемент раскрывающей строки',
    },
    {
      nameSlot: '<span class="rocky--text">headers</span>',
      props: '<span class="rocky--text"></span>',
      description: 'Слот под шапкой таблицы',
    },
    {
      nameSlot: '<span class="rocky--text">noData</span>',
      props: '<span class="rocky--text"></span>',
      description: 'Слот для уникального контента при отсутствие данных',
    },
  ]

  model = []

  sort = []

  isSort = false

  groupHeader = true

  isNotItems = false

  isNotItemsSlot = false

  isSelected = false

  isCollapse = false

  isCollapseSlot = false

  isClickRow = false

  isSlotHeaders = false

  props = {
    isSelectChildren: false,
    isLoading: false,
    isPreview: false,
    isMultiSort: false,
    isFixedHeader: false,
    colorHeader: null,
    idValue: null,
    size: 'paphia',
  }

  sizeData = [
    {
      id: 'paphia',
      title: 'paphia',
    },
    {
      id: 'creusa',
      title: 'creusa',
    },
  ]

  colorData = [
    {
      id: 'amelie',
      title: 'amelie',
    },
    {
      id: 'trainspotting',
      title: 'trainspotting',
    },
  ]

  @Watch('isSelected')
  changeIsSelected(val) {
    this.props.idValue = val ? 'id' : null;
  }

  get getListener() {
    if (!this.isClickRow) return {};
    return {
      clickRow: this.clickRow,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  clickRow(el) {
    console.log('clickRow', el);
  }

  get items() {
    if (this.isNotItems || this.isNotItemsSlot) {
      return [];
    }
    return [
      {
        id: 10,
        model: 'Камаз МКЗ 7017 Н2 03',
        owner: 'МУП «Горавтотранс»',
        number: 'A100AA77',
        boardNumber: '1000',
        mileage: '100 000',
      },
      {
        id: 20,
        model: 'Ford',
        owner: 'КБ №50',
        number: 'A200AA99',
        boardNumber: '2000',
        mileage: '200 000',
        children: this.isCollapse ? [
          {
            id: 21,
            model: 'Mondeo',
            owner: 'КБ №50, корпус 2',
            number: 'A201AA77',
            boardNumber: '2001',
            mileage: '201 000',
            children: [
              {
                id: 211,
                model: 'Mondeo1',
                owner: 'КБ №50, корпус 2',
                number: 'A201AA77',
                boardNumber: '2001',
                mileage: '201 000',
                children: [
                  {
                    id: 2111,
                    model: 'Mondeo2',
                    owner: 'КБ №50, корпус 2',
                    number: 'A201AA77',
                    boardNumber: '2001',
                    mileage: '201 000',
                  },
                  {
                    id: 2211,
                    model: 'Explorer',
                    owner: 'КБ №50, корпус 1',
                    number: 'A202AA77',
                    boardNumber: '2002',
                    mileage: '202 000',
                  },
                  {
                    id: 2311,
                    model: 'Mustang',
                    owner: 'КБ №50, корпус 4',
                    number: 'A203AA77',
                    boardNumber: '2003',
                    mileage: '203 000',
                  },
                ],
              },
              {
                id: 221,
                model: 'Explorer',
                owner: 'КБ №50, корпус 1',
                number: 'A202AA77',
                boardNumber: '2002',
                mileage: '202 000',
              },
              {
                id: 231,
                model: 'Mustang',
                owner: 'КБ №50, корпус 4',
                number: 'A203AA77',
                boardNumber: '2003',
                mileage: '203 000',
              },
            ],
          },
          {
            id: 22,
            model: 'Explorer',
            owner: 'КБ №50, корпус 1',
            number: 'A202AA77',
            boardNumber: '2002',
            mileage: '202 000',
          },
          {
            id: 23,
            model: 'Mustang',
            owner: 'КБ №50, корпус 4',
            number: 'A203AA77',
            boardNumber: '2003',
            mileage: '203 000',
          },
        ] : null,
      },
      {
        id: 30,
        model: 'Такси',
        owner: 'Такси Мобиль',
        number: 'A300AA99',
        boardNumber: '3000',
        mileage: '300 000',
        slotCollapse: this.isCollapseSlot,
      },
      {
        id: 40,
        model: 'Toyota Camry',
        owner: 'Адмнистрация города',
        number: 'A400AA99',
        boardNumber: '4000',
        mileage: '400 000',
      },
      {
        id: 50,
        model: 'Kia Optima',
        owner: 'МУП «ЦентрЖКХ»',
        number: 'A500AA99',
        boardNumber: '5000',
        mileage: '500 000',
      },
    ];
  }

  get headers() {
    if (this.groupHeader) {
      return [
        {
          headerName: 'Транспорт',
          children: [
            {
              name: 'Модель',
              field: 'model',
              sort: this.isSort,
            },
            {
              name: 'Владелец',
              field: 'owner',
              description: 'Владелец машины',
              sort: this.isSort,
            },
          ],
        },
        {
          headerName: 'Другое',
          children: [
            {
              name: 'Гос. номер',
              field: 'number',
              description: 'Гос. номер машины',
              sort: this.isSort,
              slot: this.isCollapse,
            },
            {
              name: 'Борт. номер',
              field: 'boardNumber',
              // description: 'Борт. номер машины',
              sort: this.isSort,
              slot: this.isCollapse,
            },
            {
              name: 'Пробег, км',
              field: 'mileage',
              description: 'Пробег, км',
              sort: this.isSort,
              slot: this.isCollapse,
            },
          ],
        },
      ];
    }
    return [
      {
        name: 'Модель',
        field: 'model',
        description: 'Модель машины',
        sort: this.isSort,
      },
      {
        name: 'Владелец',
        field: 'owner',
        description: 'Владелец машины',
        sort: this.isSort,
      },
      {
        name: 'Гос. номер',
        field: 'number',
        description: 'Гос. номер машины',
        sort: this.isSort,
        slot: this.isCollapse,
      },
      {
        name: 'Борт. номер',
        field: 'boardNumber',
        description: 'Борт. номер машины',
        sort: this.isSort,
        slot: this.isCollapse,
      },
      {
        name: 'Пробег, км',
        field: 'mileage',
        description: 'Пробег, км',
        sort: this.isSort,
        slot: this.isCollapse,
      },
    ];
  }

  get codeView() {
    return generateCodeToString('table', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
