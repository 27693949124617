<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент, который позволяет из&nbsp;предустановленного множества выбрать один или несколько
      пунктов. По&nbsp;умолчанию может быть как пустым, так и&nbsp;заполненным.
    </template>
    <template #component>
      <div style="width: 320px">
        <r-select
          v-model="model"
          :items="items"
          v-bind="props"
          :call-search="searchIsServer ? callBackFindSelect : null"
          :call-scroll="isLoadPage ? callBackScroll : null"
          :groups-list="groups"
        >

          <template #header v-if="dataSlot.header">
            <div
              class="leon--bg px-4 py-2 fightclub--text caprino">slot header</div>
          </template>

          <template #body v-if="dataSlot.body">
            <div
              class="leon--bg px-4 py-2 fightclub--text caprino">slot body</div>
          </template>
        </r-select>
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6" gap-hidden>
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
        />
        <r-switcher
          title="Model Is Array"
          v-model="modelIsArray"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="isSearch"
          v-model="props.isSearch"
        />
        <r-switcher
          title="isClearSearchClose"
          v-model="props.isClearSearchClose"
        />
        <r-switcher
          :disabled="!props.isSearch"
          title="Server callback search"
          v-model="searchIsServer"
        />
        <r-switcher
          title="Server callback next page"
          v-model="isLoadPage"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
        <r-switcher
          title="returnObject"
          v-model="props.returnObject"
        />
        <r-switcher
          title="Group list"
          v-model="isGroup"
        />
      </r-row>
      <r-row
        class="mb-6" gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="PopoverOptions.position"
            v-model="props.popoverOptions.position"
            :items="dataPopoverPosition"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="PopoverOptions.contentWidthActivator"
            v-model="props.popoverOptions.contentCoefficientWidth"
            :items="dataContentCoefficientWidth"
          />
        </r-col>
      </r-row>
    </template>
    <template #slotsView>
      <r-row
        gap-hidden>
        <r-switcher
          title="header"
          v-model="dataSlot.header"
        />
        <r-switcher
          title="body"
          v-model="dataSlot.body"
        />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
      <div class="taleggio mb-8">SlotsScoped</div>
      <r-table
        class="mb-8"
        :headers="headersSlotsScoped"
        :items="itemsSlotsScoped"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
        <template #props="{ item }">
          <div v-html="item.props" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <select-all-checked-slot-body />
      <select-clear-model />
      <select-groups-items />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';
import SelectAllCheckedSlotBody from '@/components/examples/Select/SelectAllCheckedSlotBody.vue';
import SelectClearModel from '@/components/examples/Select/SelectClearModel.vue';
import SelectGroupsItems from '@/components/examples/Select/SelectGroupsItems.vue';

@Component({
  components: {
    SelectGroupsItems, SelectClearModel, SelectAllCheckedSlotBody, ComponentPage,
  },
})
export default class Select extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlotsScoped = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'props',
      name: 'props',
      description: 'Входные параметры слота',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">Array | Object | String | Number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="matrix--text">Array</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Список для выбора',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Описание компонента в placeholder',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Иконка слева от контента',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">val</span>',
      typeData: '<span class="matrix--text">Object | String | Number | Boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Значение которое запишется в модель или вернется эвентом @input[boolean, object, string, number]',
    },
    {
      nameProp: '<span class="rocky--text">returnObject</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'При val с типом Object, будет возвращаться полностью объект, а не индификатор из параметра "idValue"',
    },
    {
      nameProp: '<span class="rocky--text">isSearch</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Вывод строки поиска в Popover для фильтрации списка (если указан "callSearch", то введнная строка возвращается в callback метод)',
    },
    {
      nameProp: '<span class="rocky--text">isClearSearchClose</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Очищает поле поиска после закрытия окна списка',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Состояние загрузки списка',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"id"</span>',
      description: 'Параметр для определение в списках элементов объекта уникального ключа',
    },
    {
      nameProp: '<span class="rocky--text">titleValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"title"</span>',
      description: 'Параметр для определение в списках элементов объекта описание',
    },
    {
      nameProp: '<span class="rocky--text">subTitleValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"subTitle"</span>',
      description: 'Параметр для определение в списках элементов объекта дополнительное описание',
    },
    {
      nameProp: '<span class="rocky--text">iconValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"icon"</span>',
      description: 'Параметр для определение в списках элементов объекта наименование иконки',
    },
    {
      nameProp: '<span class="rocky--text">callSearch</span>',
      typeData: '<span class="matrix--text">Async Function (query: string, options?: { [key: string]: any}): Promise &lt;optionObjectChecked[] | string[] | void&gt;</optionObjectChecked></span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр асихроной функции для получение списка из ответа апи от родителя, при использование серверного поиска работайте с returnObject (для корректной работы компонента в рамках вывода выбранных значений)***',
    },
    {
      nameProp: '<span class="rocky--text">callScroll</span>',
      typeData: '<span class="matrix--text">Async Function: void</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Срабатывает когда скрол в списки достигает низа контента для пагинационной запроса',
    },
    {
      nameProp: '<span class="rocky--text">groupsList</span>',
      typeData: '<span class="matrix--text">GroupsList</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Массив групп для группировки элементов списка (отключает входной параметр callScroll)',
    },
    {
      nameProp: '<span class="rocky--text">popoverOptions</span>',
      typeData: '<span class="matrix--text">Pick&lt;RPopover, \'position\' | \'contentCoefficientWidth\' | \'isCheckAutoScrollBlock\'&gt;</span>',
      defaultValue: '<span class="alien--text">{\n'
        + '      position: \'center-bottom\',\n'
        + '      contentCoefficientWidth: 1,\n'
        + '      isCheckAutoScrollBlock: false,\n'
        + '      idBlockAppend: null\n'
        + '    }</span>',
      description: 'Входной параметр включающий себя надстройки компонента RPopover',
    },
    {
      nameProp: '<span class="rocky--text">listItemOptions</span>',
      typeData: '<span class="matrix--text">Pick&lt;RListItem, \'countLine\'&gt;</span>',
      defaultValue: '<span class="alien--text">{\n'
        + '      countLine: 1,\n'
        + '    }</span>',
      description: 'Входной параметр включающий себя надстройки компонента RListItem',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">focusout</span>',
      description: 'Возврощается событие при закрытие списка выбора',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">header</span>',
      description: 'Контентная область слота над списком и поиском',
    },
  ]

  itemsSlotsScoped = [
    {
      nameSlot: '<span class="rocky--text">body</span>',
      props: '<span class="rocky--text">{ close: Function }</span>',
      description: 'Контентная область слота над списком',
    },
  ]

  items = [
    {
      id: 1,
      title: 'Title1',
      subTitle: 'SubTitle1',
      icon: 'avatar',
    },
    {
      id: 2,
      title: 'Title2',
      subTitle: 'SubTitle2',
      icon: 'avatar',
      group: 'group2',
    },
    {
      id: 3,
      title: 'Title3',
      subTitle: 'SubTitle3',
      icon: 'avatar',
      group: 'group1',
    },
    {
      id: 4,
      title: 'Title4',
      subTitle: 'SubTitle4',
      icon: 'avatar',
      group: 'group3',
    },
    {
      id: 5,
      title: 'Title5',
      subTitle: 'SubTitle5',
      icon: 'avatar',
      group: 'group2',
    },
    {
      id: 6,
      title: 'Title6',
      subTitle: 'SubTitle6',
      icon: 'avatar',
      group: 'group3',
    },
  ]

  itemsFind = [
    {
      id: 1,
      title: 'Title1',
      subTitle: 'SubTitle1',
      icon: 'avatar',
      group: 'group1',
    },
    {
      id: 2,
      title: 'Title2',
      subTitle: 'SubTitle2',
      icon: 'avatar',
      group: 'group2',
    },
    {
      id: 3,
      title: 'Title3',
      subTitle: 'SubTitle3',
      icon: 'avatar',
      group: 'group1',
    },
    {
      id: 4,
      title: 'Title4',
      subTitle: 'SubTitle4',
      icon: 'avatar',
      group: 'group3',
    },
    {
      id: 5,
      title: 'Title5',
      subTitle: 'SubTitle5',
      icon: 'avatar',
      group: 'group2',
    },
    {
      id: 6,
      title: 'Title6',
      subTitle: 'SubTitle6',
      icon: 'avatar',
      group: 'group3',
    },
  ]

  get groups() {
    return this.isGroup ? [
      {
        id: 'group1',
        name: 'Group 1',
      },
      {
        id: 'group2',
        name: 'Group 2',
      },
      {
        id: 'group3',
        name: 'Group 3',
      },
    ] : [];
  }

  model = null

  modelIsArray = false

  searchIsServer = false

  isLoadPage = false

  isGroup = false

  props = {
    disabled: false,
    isSearch: false,
    isLoading: false,
    isClearSearchClose: false,
    error: false,
    icon: null,
    label: 'Title',
    type: 'default',
    errorMessage: null,
    returnObject: false,
    groupsList: [],
    popoverOptions: {
      position: 'left-bottom',
      contentCoefficientWidth: 1,
    },
  }

  dataPopoverPosition = [
    'center-bottom',
    'center-top',
    'left-bottom',
    'left-top',
    'right-bottom',
    'right-top',

    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
  ]

  dataContentCoefficientWidth = [
    {
      id: 1,
      title: '1',
    },
    {
      id: 1.2,
      title: '1.2',
    },
    {
      id: 1.4,
      title: '1.4',
    },
    {
      id: 1.6,
      title: '1.6',
    },
    {
      id: 1.8,
      title: '1.8',
    },
    {
      id: 2,
      title: '2',
    },
  ]

  dataSlot = {
    header: false,
    body: false,
  }

  get codeView() {
    return generateCodeToString('select', this.props, this.dataSlot);
  }

  @Watch('modelIsArray')
  changeSize(val) {
    if (val) {
      this.model = [];
    } else {
      this.model = null;
    }
  }

  @Watch('searchIsServer')
  changeSearchIsServer(val) {
    this.props.returnObject = val;
  }

  @Watch('props.returnObject')
  changeReturnObject() {
    if (this.modelIsArray) {
      this.model = [];
    } else {
      this.model = null;
    }
  }

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'map',
      title: 'map',
    },
  ]

  // eslint-disable-next-line class-methods-use-this
  get dataIcons() {
    return [{
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
    ...getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    }))];
  }

  // eslint-disable-next-line class-methods-use-this,consistent-return
  callBackFindSelect(query: string): Promise<any> {
    return new Promise<void>((res) => {
      setTimeout(() => {
        if (query) {
          this.items = this.itemsFind
            .filter((el: any) => el.title
              .toLowerCase()
              .includes((query as string).toLowerCase()));
        } else {
          this.items = [...this.itemsFind];
        }
        res();
      }, 2500);
    });
  }

  loadDataItems(query) {
    return new Promise((res) => {
      const lastIndex = this.items.length;
      setTimeout(() => {
        const items = [];
        for (let i = lastIndex; i < lastIndex + 2; i++) {
          items.push({
            id: i + 1,
            title: `Title${i + 1}`,
            subTitle: `SubTitle${i + 1}`,
            icon: 'avatar',
          });
        }
        if (query) {
          res(items.filter((el: any) => el.title
            .toLowerCase()
            .includes((query as string).toLowerCase())));
        } else {
          res(items);
        }
      }, 2500);
    });
  }

  // eslint-disable-next-line class-methods-use-this,consistent-return
  async callBackScroll(query): Promise<void> {
    if (this.items.length < 10) {
      const data = await this.loadDataItems(query);
      this.items.push(...(data as any));
    }
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
