<template>
  <component-page :code-view="codeView" :colorPreviewBlock="props.color === 'amelie' ? 'django' : 'amelie'">
    <template #description>
      Развернуть/свернуть блок
    </template>
    <template #component>
      <div style="width: 100%">

        <r-accordion
          :key="props.size"
          v-bind="props"
        >
          <template #actions>
            <div
              class="d-flex align-items-center justify-content-center rocky--text avengers--bg"
              style="height: 100%"
            >
              actions
            </div>
          </template>
          <template #content>
            <div
              ref="content"
              class="d-flex align-items-center justify-content-center rocky--text avengers--bg"
              style="height: 100px"
            >
              content
            </div>
          </template>
        </r-accordion>
      </div>
    </template>
    <template #props>
      <r-row
        gap-hidden
        class="mb-6"
      >
        <r-switcher
          title="isShape"
          v-model="props.isShape"
        />
        <r-switcher
          title="notShorter"
          v-model="props.notShorter"
        />
      </r-row>
      <r-row
        gap-hidden
      >
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        >
          <r-input
            label="Title"
            v-model="props.title"
            :is-clear="false"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        >
          <r-select
            label="Size"
            v-model="props.size"
            :items="sizeData"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        >
          <r-select
            :disabled="!props.isShape"
            label="Color"
            v-model="props.color"
            :items="colorData"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Accordion extends Vue {
  // mounted() {
  //   setInterval(() => {
  //     if (this.$refs.content) {
  //       if ((this.$refs.content as HTMLElement) instanceof Vue && (this.$refs.content as any).$el) {
  //         ((this.$refs.content as any).$el as HTMLElement).style.height = ((this.$refs.content as any).$el as HTMLElement).style.height === '300px' ? '500px' : '300px';
  //       } else {
  //         (this.$refs.content as HTMLElement).style.height = (this.$refs.content as HTMLElement).style.height === '100px' ? '200px' : '100px';
  //       }
  //       console.log();
  //     }
  //   }, 2000);
  // }

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">isOpening</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Раскрывает контент при инициализации компонента',
    },
    {
      nameProp: '<span class="rocky--text">isShape</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Отображение с фоном или нет',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Заголовок аккордеона',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"amphinomis"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"walle"</span>',
      description: 'Цвет фона (применяется при isShape = true)',
    },
    {
      nameProp: '<span class="rocky--text">notShorter</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отключение использования RShorter внутри компонента',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">actions</span>',
      description: 'Слот в шапке для разных событий',
    },
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Слот области контента для скрытого контента',
    },
  ]

  props = {
    isShape: false,
    isOpening: false,
    notShorter: false,
    size: 'amphinomis',
    color: 'walle',
    title: 'Title text',
  }

  sizeData = [
    {
      id: 'amphinomis',
      title: 'amphinomis',
    },
    {
      id: 'celareino',
      title: 'celareino',
    },
    {
      id: 'meliphia',
      title: 'meliphia',
    },
    {
      id: 'pasithea',
      title: 'pasithea',
    },
    {
      id: 'criamisa',
      title: 'criamisa',
    },
  ]

  colorData = [
    {
      id: 'walle',
      title: 'walle',
    },
    {
      id: 'amelie',
      title: 'amelie',
    },
  ]

  get codeView() {
    return generateCodeToString('accordion', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
