<template>
  <examples-block
    gitUrl="src/components/examples/Popover/PopoverSelectedBadge.vue"
    title="Список множественного выбора с badge">
      <r-popover class="content" position="left-bottom">
        <template #activator="{ on }">
          <div class="content__activator" ref="view">
            <r-button-simple
              v-on="on"
              rounded
              icon="add"
              size="spelino"
            />
            <r-badge
              v-for="item in model"
              :key="item.id"
              :title="item.title"
              :not-shorter="true"
              :color="item.color"
            />
            <r-button-action
              :title="isFullView ? 'Свернуть' : `Ещё ${countHidden}`"
              @click="isFullView = !isFullView, checkViewBlock()"
              ref="change"
              color="harakiri"
              size="briscola"
              :not-shorter="true"
              style="visibility: hidden;"
            />
          </div>
        </template>
        <template #content>
          <div class="px-1">
            <div
              class="px-3 pt-5 pb-4">
              <r-input
                before-icon="search"
                v-model="query"
                label="Поиск"
              />
            </div>
            <div class="px-3 content__list">
<!--              <r-checkbox-->
<!--                v-for="item in items"-->
<!--                :key="item.id"-->
<!--                v-model="model"-->
<!--                :val="item"-->
<!--                return-object-->
<!--                :sub-title="item.subTitle"-->
<!--                class="mb-2"-->
<!--              >-->
<!--                <template #title>-->
<!--                  <r-badge :title="item.title" :color="item.color" />-->
<!--                </template>-->
<!--              </r-checkbox>-->
              <r-list-group>
                <template #content>
                  <r-list-item
                    v-for="item in items"
                    :key="item.id"
                    :val="item"
                    v-model="model"
                    return-object
                  >
                    <template #content>
                      <r-badge size="ptelea" :title="item.title" :color="item.color" />
                    </template>
                  </r-list-item>
                </template>
              </r-list-group>
            </div>
          </div>
        </template>
      </r-popover>
  </examples-block>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'PopoverSelectedBadge',
  components: { ExamplesBlock },
  mounted() {
    this.items = Array.from({ length: 20 }, (element, index) => {
      const id = index + 1;
      return {
        id,
        title: `Item ${id}`,
        subTitle: 'subTitle',
        color: this.colorType[Math.floor(Math.random() * this.colorType.length)],
      };
    });
    this.checkViewBlock();
  },
  data: () => ({
    colorType: ['rocky', 'matrix', 'lebowski', 'fargo', 'gentlemen', 'metropolis', 'fightclub', 'aquaman', 'goodfellas'],
    model: [],
    isFullView: false,
    countHidden: 0,
    query: null,
    items: [],
  }),
  watch: {
    model() {
      this.checkViewBlock();
    },
  },
  methods: {
    checkViewBlock() {
      this.$nextTick(() => {
        const { children } = this.$refs.view;
        let sumWidth = 0;
        let isView = true;
        this.$refs.change.$el.style.visibility = 'hidden';
        this.countHidden = 0;
        for (const item of children) {
          item.style.display = 'inline-flex';
          sumWidth += item.clientWidth + 8;
          if (sumWidth >= this.$refs.view.clientWidth) {
            isView = false;
            this.$refs.change.$el.style.visibility = 'visible';
          }
          if (!isView && this.$refs.change.$el !== item && !this.isFullView) {
            item.style.display = 'none';
            this.countHidden++;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content{
  width: 300px;
  &__activator{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  &__list{
    max-height: 200px;
    overflow: auto;
  }
}
</style>
