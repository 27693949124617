<template>
  <component-page :code-view="codeView">
    <template #description>
      Чекбокс используется, когда доступен список опций, и&nbsp;пользователю необходимо
      выбрать одну или несколько из&nbsp;них. <br />
      Чекбокс не&nbsp;запускает действие немедленно.
      Как правило для этого нужно нажать подтверждающую кнопку.
      Для немедленного включения какого-то режима в&nbsp;интерфейсе лучше подходит Switcher.
      <br />
      Иногда списки с&nbsp;чекбоксами содержат дочерние элементы. Если выбрана только часть дочерних
      чекбоксов, родительский переходит в&nbsp;состояние partial&nbsp;&mdash; частично выбран.
    </template>
    <template #component>
      <r-checkbox
        v-model="model"
        v-bind="props"/>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="isPartial"
          v-model="props.isPartial"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
        <r-switcher
          title="notShorter"
          v-model="props.notShorter"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="SubTitle"
            v-model="props.subTitle" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage" />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <checkbox-and-button-link />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import CheckboxAndButtonLink from '@/components/examples/Checkbox/CheckboxAndButtonLink.vue';

@Component({
  components: { CheckboxAndButtonLink, ComponentPage },
})
export default class Checkbox extends Vue {
  model = false

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">any</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Модель компонента [Array, Boolean, String, Object, Number]',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">isPartial</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Состояние промежуточного выбора саписка, например в таблице при частином выборе строк',
    },
    {
      nameProp: '<span class="rocky--text">val</span>',
      typeData: '<span class="matrix--text">any</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Значение которое запишется в модель или вернется эвентом @input[boolean, object, string, number]',
    },
    {
      nameProp: '<span class="rocky--text">returnObject</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'При val с типом Object, будет возвращаться полностью объект, а не его индификатор из параметра "idValue"',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"id"</span>',
      description: 'Параметр для определение в объектах уникального ключа',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">null</span>',
      description: 'Описание поля',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">null</span>',
      description: 'дополнительное описание поля',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст ошибки при активном свойстве error и subTitle',
    },
    {
      nameProp: '<span class="rocky--text">isTabIndex</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Флаг для отработки табуляции или отключение контрола для клавиатурного поиска',
    },
    {
      nameProp: '<span class="rocky--text">notShorter</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отключение использования RShorter внутри компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">title</span>',
      description: 'Слот для вставки произвольного контекста в область вывода входного параметра "title" (имеет наибольший приоритет)',
    },
  ]

  props = {
    error: false,
    disabled: false,
    isPartial: false,
    notShorter: false,
    title: 'Title text',
    subTitle: 'SubTitle text',
    errorMessage: null,
  }

  get codeView() {
    return generateCodeToString('checkbox', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
